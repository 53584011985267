@tailwind base;
@tailwind components;
@tailwind utilities;

.bg-primary {
  background-color: rgba(25, 34, 53, 0.9);
}

.bg-secondary {
  background-color: #192235;
}

/* Add smooth scrolling to the page */
html {
  scroll-behavior: smooth;
}

.main-content {
  @apply mx-auto px-4 pt-4 md:pt-24;
}

/* Header animations */
@keyframes fadeSlideOut {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  40% {
    opacity: 1;
    transform: translateY(-4px);
  }
  80% {
    opacity: 0.7;
    transform: translateY(-12px);
  }
  100% {
    opacity: 0;
    transform: translateY(-16px);
  }
}
